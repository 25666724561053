<template>
  <page-layout>
    <span slot="header-name">{{ $tf('reports') }}</span>
    <page-reports-actions slot="header-content-actions" :actions="actions" :plural="plural" />
    <page-reports-table-navigation ref="navigation" slot="header-content-navigation" :state="state" :change-handler="loadReports" />
    <page-reports-table slot="content" :items="items" :loading="state.loading" @selection-change="handleSelectionChange" />
  </page-layout>
</template>

<script>
import { ReportStatus } from '@/store/reports/enums.reports.js';
import { Component, Vue, Watch } from 'vue-property-decorator';
import PageReportsTableNavigation from '@/components/tables/navigation.vue';
import PageReportsActions from './page-reports-actions';
import PageReportsTable from './page-reports-table';
import PageLayout from '@/components/page/layout';
import { applyReportsChanges } from './reports-helpers';
import { saveAs } from 'file-saver';

const ReportsLoadingTimeout = 10000;

@Component({
  name: 'PageReports',
  components: {
    PageReportsTableNavigation,
    PageReportsActions,
    PageReportsTable,
    PageLayout
  }
})
export default class extends Vue {
  items = [];
  timeout = null;
  selection = [];
  loaders = {
    update: false,
    delete: false,
    download: false
  };

  get state() {
    return this.$store.state.reports;
  }

  get actions() {
    return [this.downloadAction, this.updateAction, this.deleteAction];
  }

  get updateAction() {
    return {
      key: 'update',
      label: this.$tf('update'),
      loading: this.loaders.update,
      disabled: !this.areReportsSelected,
      handler: this.restartSelectedReports
    };
  }

  get downloadAction() {
    return {
      key: 'download',
      label: this.$tf('download'),
      loading: this.loaders.download,
      disabled: !this.selectedCompletedReports.length,
      handler: this.downloadSelectedCompletedReports
    };
  }

  get deleteAction() {
    return {
      key: 'delete',
      label: this.$tf('delete'),
      loading: this.loaders.delete,
      disabled: !this.areReportsSelected,
      handler: this.deleteSelectedReports
    };
  }

  get areReportsSelected() {
    return this.selection.length > 0;
  }

  get selectedCompletedReports() {
    return this.selection.filter(({ status }) => status === ReportStatus.COMPLETED);
  }

  get plural() {
    return this.selection.length > 1;
  }

  mounted() {
    this.loadReportsContinuously();
  }

  beforeDestroy() {
    clearTimeout(this.timeout);
  }

  @Watch('items')
  handleItemsChange(items) {
    if (!items.length) {
      this.$refs.navigation.prevPage();
    }
  }

  @Watch('state.items', { immediate: true })
  handleStateItemsChange(changes) {
    applyReportsChanges(this.items, changes);
  }

  loadReportsContinuously() {
    this.loadReports().then(() => {
      this.timeout = setTimeout(this.loadReportsContinuously, ReportsLoadingTimeout);
    });
  }

  loadReports() {
    return this.$store.dispatch(this.state.Action.Get).catch(this.notifyWhenErrorThrown);
  }

  async restartSelectedReports() {
    this.loaders.update = true;
    for (const { id } of this.selection) {
      try {
        await this.$store.dispatch(this.state.Action.Restart, { id });
        this.$notify({
          type: 'success',
          message: `${this.$tf('action | success')} (ID: ${id})`
        });
      } catch (e) {
        this.notifyWhenErrorThrown(e);
      }
    }
    await this.loadReports();
    this.loaders.update = false;
  }

  async deleteSelectedReports() {
    this.loaders.delete = true;
    for (const [id, action] of this.dispatchDeleteActionsSequence()) {
      try {
        await action;
        this.$notify({
          type: 'success',
          message: `${this.$tf(['common.action', 'common.success'])} (ID: ${id})`
        });
      } catch (e) {
        this.notifyWhenErrorThrown(e);
      }
    }
    await this.loadReports();
    this.loaders.delete = false;
  }

  *dispatchDeleteActionsSequence() {
    for (const { id } of this.selection) {
      yield [id, this.$store.dispatch(this.state.Action.Delete, { id })];
    }
  }

  downloadSelectedCompletedReports() {
    this.selectedCompletedReports.forEach(({ report_file }) => {
      const fileName = report_file.slice(report_file.lastIndexOf('/') + 1);
      saveAs(report_file, fileName);
    });
  }

  handleSelectionChange(selection) {
    this.selection = selection;
  }

  notifyWhenErrorThrown(e) {
    this.$notify({
      duration: 0,
      message: this.$createElement('message-box', { props: { e } })
    });
  }
}
</script>
